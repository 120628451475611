.comentarios-container {
  text-align: center;
  font-family: Arial, sans-serif;
  max-width: 100%;
  padding: 20px;
  overflow: hidden;
  margin-bottom: -50px;
}

.comentarios-container h2 {
  font-size: 2.5em;
  color: #161616;
  margin-bottom: 5px;
  font-weight: bold;
}

.comentarios-container p {
  font-size: 1.2em;
  color: #5a5a5a;
  margin-bottom: -30px;
}

.comentarios-carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 30em;
}

.carousel-button {
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 50px;
  height: 40px;
  z-index: 2;
}

.carousel-button img {
  width: 100%;
  height: auto;
}

.carousel-button:hover {
  transform: scale(1.1);
}

.comentarios-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  transition: transform 0.5s ease-in-out;
}

.comentario-card {
  flex: 0 0 auto;
  width: 250px;
  height: 300px;
  background: #ffffff;
  border-radius: 20px !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 15px;
  margin: 0 15px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  position: relative;
}

.comentario-imagen {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 15px;
}

.comentario-info {
  text-align: left;
}

.comentario-info h3 {
  font-size: 30px !important;
  color: #ffcc00;
  width: auto;
  letter-spacing: 0.1em;
  font-weight: bold;
  margin-top: -15px;
}

.comentario-info .p-nombre {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  margin-bottom: 0em;
  margin-top: 10px;
}

.comentario-info .p-texto {
  font-size: 15px;
  color: #5c5c5c;
  margin-top: 10px;
  line-height: 1.5;
  margin-left: 0em;
}

.comentarios-btn {
  background: #46495a;
  color: white;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 10px 20px;
  transition: background 0.3s ease-in-out, transform 0.3s ease;
}

.comentarios-btn:hover {
  background: #5a5d6d;
  transform: scale(1.05);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeInModal 0.5s ease-in-out;
}

@keyframes fadeInModal {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: left;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-content input,
.modal-content select,
.modal-content textarea {
  width: calc(100% - 20px);
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-submit-btn,
.modal-cancel-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.modal-submit-btn {
  background: #28a745;
  color: white;
}

.modal-submit-btn:hover {
  transform: scale(1.05);
  background: #218838;
}

.modal-cancel-btn {
  background: #dc3545;
  color: white;
  margin-left: 10px;
}

.modal-cancel-btn:hover {
  transform: scale(1.05);
  background: #c82333;
}

/* Media Queries para ajustar el número de comentarios */
@media (max-width: 1200px) {
  .comentario-card {
    width: 220px;
    height: 280px;
  }
}

@media (max-width: 992px) {
  .comentario-card {
    width: 200px;
    height: 260px;
  }
}

@media (max-width: 768px) {
  .comentario-card {
    margin-top: -20px;
    width: 240px;
    height: 290px;
  }

  .comentarios-carousel-container{
    margin-top: -50px;
    margin-bottom: -30px;
  }

  .comentarios-container{
    margin-top: -40px;
    margin-bottom: -60px;
  }

  .comentarios-container h2{
    font-size: 45px;
  }

  .comentarios-container p{
    font-size: 20px;
  }
}

@media (max-width: 600px){
  .comentario-card{
    width: 320px;
    height: 330px;
  }
}

@media (max-width: 576px) {
  .comentario-card {
    width: 160px;
    height: 220px;
  }
}


@media (max-width: 480px) {
  .comentario-card {
    width: 270px;
    height: 320px;
  }

  .comentarios-container h2{
    font-size: 2.3em;
  }
}

@media (max-width: 420px) {
  .comentario-card {
    width: 210px;
    height: 300px;
  }
}

