/* Admin Dashboard General */
.admin-dashboard {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0; /* si deseas quitar padding global, ajústalo */
}

/* Encabezado del Dashboard */
.dashboard-header {
  width: 100%; /* Ocupa todo el ancho */
  background: linear-gradient(to right, #f8f8f8, #e0e5eb);
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  /* El texto puede ser left o center, según prefieras */
  text-align: left;
  box-sizing: border-box;
}

/* Título dentro del encabezado */
.dashboard-header h1 {
  font-size: 24px;
  color: #333;
  margin: 0;
  margin-left: 30px;
}

/* Barra de navegación de botones */
.dashboard-navigation {
  display: flex;
  gap: 10px;
  justify-content: center; /* Centra los botones horizontalmente */
  margin-bottom: 20px;
  flex-wrap: wrap; /* Permite que los botones bajen de línea si no caben */
}

/* Botones de navegación (estilo "pill") */
.nav-button {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 20px; /* forma de píldora */
  padding: 10px 20px;
  color: #333;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.146);
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.1s ease;
}

/* Hover en los botones de navegación */
.nav-button:hover {
  background-color: #f1f1f1;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.146);
  transform: scale(1.02);
}
/* Estado activo para indicar la sección seleccionada */
.nav-button.active {
  background-color: #d2d2d2;
}

/* Efecto de "presionado" */
.nav-button:active {
  transform: scale(0.97);
}

.dashboard-header h2 {
  font-size: 18px;
  color: #666;
}

.logout-button,
.back-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #d9534f;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.logout-button:hover,
.back-button:hover {
  background-color: #c9302c;
}

/* Formulario de Autos */
.admin-form {
  width: 100%;
  max-width: 600px;
  background: #f9f9f9;
  border-radius: 20px;
  padding: 20px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(35, 22, 22, 0.2);
}

.admin-form input,
.admin-form textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 15px;
  border: 2px solid #ddd;
  border-radius: 20px;
  box-sizing: border-box;
}

.admin-form textarea {
  height: 100px;
  resize: vertical;
}

.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #5cb85c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #4cae4c;
}

/* =========================================== */
/* Sección: Gestionar Carrusel Vehículos       */
/* =========================================== */

.vehiculos-section {
  margin-top: 20px;
  text-align: center;
}

/* Título principal de la sección */
.vehiculos-section h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

/* Formulario para agregar/editar vehículos */
.vehiculos-form {
  width: 700px;
  max-width: 90%;
  margin: 0 auto 30px auto;
  background: #f9f9f9;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(35, 22, 22, 0.2);
  text-align: center;
  transition: transform 0.3s ease;
}

.vehiculos-form:hover {
  transform: scale(1.02);
}

/* Inputs y textarea del formulario */
.vehiculos-form input,
.vehiculos-form textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 15px;
  border: 2px solid #ddd;
  border-radius: 15px;
  box-sizing: border-box;
}

/* Ajuste específico para el textarea */
.vehiculos-form textarea {
  height: 100px;
  resize: vertical;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* Botón principal del formulario */
.vehiculos-submit-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #5cb85c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.vehiculos-submit-button:hover {
  background-color: #4cae4c;
}

/* Título de la lista (debajo del formulario) */
.vehiculos-list-title {
  font-size: 20px;
  margin-bottom: 15px;
}

/* Grilla de vehículos */
.vehiculos-list {
  display: grid;
  /* Ajusta el tamaño mínimo de la tarjeta para cada vehículo */
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 40px;
  margin: 20px auto;
  max-width: 1200px;
  justify-items: center;
}

/* Tarjeta individual de cada vehículo */
.vehiculo-card {
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(35, 22, 22, 0.2);
  text-align: center;
  width: 250px; /* Ajusta si quieres más grande o más pequeña */
  height: 300px; /* Ajusta la altura total */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease;
}

.vehiculo-card:hover {
  transform: scale(1.05);
}

/* Imagen principal del vehículo */
.vehiculo-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

/* Contenedor para el logo y el título del vehículo */
.vehiculo-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

/* Logo de la marca */
.vehiculo-logo {
  height: 30px;
  margin-right: 10px;
}

/* Título con marca y modelo */
.vehiculo-info h2 {
  font-size: 16px;
  color: #333;
  margin: 0;
}

/* Contenedor de los dos botones en la misma fila */
.vehiculo-buttons {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  gap: 10px; /* Espacio entre botones */
  /* margin-top: auto;  */
}

/* Botones Editar/Eliminar dentro de la tarjeta */
.vehiculo-edit-button,
.vehiculo-delete-button {
  flex: 1; /* Para que ambos ocupen el mismo ancho dentro del contenedor */
  padding: 7px 10px;
  font-size: 14px;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

/* Botón Editar */
.vehiculo-edit-button {
  background-color: #0275d8;
}
.vehiculo-edit-button:hover {
  background-color: #025aa5;
}

/* Botón Eliminar */
.vehiculo-delete-button {
  background-color: #d9534f;
}
.vehiculo-delete-button:hover {
  background-color: #c9302c;
}

/* =============================== */
/* Sección para "Gestionar Slides" */
/* =============================== */
.slides-section {
  margin-top: 20px;
  text-align: center;
}

.slides-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Contenedor para subir nuevas imágenes */
.slides-upload-container {
  width: 700px;
  max-width: 90%;
  border-radius: 10px;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
}

.slides-upload-container input[type="file"] {
  margin-bottom: 20px;
}

.slides-add-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #5cb85c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.slides-add-button:hover {
  background-color: #4cae4c;
}

/* Mensaje de error (cuando hay más de 4 imágenes) */
.slides-error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

/* Contenedor de las imágenes en 2 columnas fijas y más juntas */
.slides-images {
  display: grid;
  /* Dos columnas de 450px cada una, total ~900px */
  grid-template-columns: 450px 450px;
  /* Ajusta la separación entre columnas/filas */
  gap: 40px;
  /* Centra todo y define un ancho máximo */
  max-width: 920px;
  margin: 20px auto;
  justify-items: center;
  margin-bottom: 40px;
}

/* Cada tarjeta con el ancho fijo (450px) */
.slide-card {
  text-align: center;
  width: 450px;
}

/* Imagen grande que ocupa todo el ancho de la tarjeta */
.slide-image1 {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.slide-image1:hover {
  transform: scale(1.05);
}

/* Sección de Carrusel Secundario */
.carousel-section {
  margin-top: 20px;
}

.add-carousel {
  width: 700px;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin: 0 auto;
}

.add-carousel h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
}

.add-carousel input {
  width: calc(100% - 20px);
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.add-carousel .add-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #5cb85c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #4cae4c;
}

.carousel-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 60px;
}

.carousel-image1 {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.carousel-image1:hover {
  transform: scale(1.05);
}

.carousel-card {
  text-align: center;
}

.carousel-card img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.edit-button,
.update-button {
  background-color: #0275d8;
}

.cancel-button {
  background-color: #d9534f;
}

/* Sección "Nosotros" */
.nosotros-section1 {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 20px;
  background: linear-gradient(135deg, #f5f7facf, #cbd8ecab);
  border: 2px solid #ddd;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease;
}

.nosotros-input1,
.nosotros-textarea1 {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 20px;
  margin-bottom: 15px;
}

.nosotros-textarea1 {
  height: 100px;
  resize: vertical;
}

.nosotros-images1 {
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  gap: 20px;
  margin: 20px auto;
  max-width: 1200px;
}

.nosotros-image-card1 {
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nosotros-card-image1 {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.nosotros-image-card1 .nosotros-input1 {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.save-button {
  margin-top: 20px;
  padding: 10px 25px;
  font-size: 16px;
  color: #fff;
  background-color: #5cb85c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #4cae4c;
}

/* Comentarios */
.dashboard-comentarios-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
}

.comentarios-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  padding: 20px 0;
}

.comentario-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
}

.comentario-imagen {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.comentario-info h3 {
  font-size: 18px;
  color: #ffcc00;
  margin-bottom: 5px;
}

.comentario-info p {
  font-size: 14px;
  color: #333;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

/* Animaciones y Carrusel Principal */
html {
  scroll-behavior: smooth;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-catalogo {
  animation: slideInRight 0.5s ease-out;
}

.vehiculo-carousel-container {
  width: 70vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  animation: slide-up 0.5s ease-in;
}

.vehiculo-carousel {
  display: flex;
  position: absolute;
  overflow: hidden;
  border-radius: 15px;
  width: 50vw;
  max-height: calc(50vw * 9 / 16);
  margin-top: -400px;
  transition: transform 0.3s ease-in-out;
}

.vehiculo-carousel:hover {
  transform: scale(1.03);
}

.vehiculo-carousel-image {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  object-fit: cover;
  display: block;
  transition: translate 1s ease-in-out;
  border: none;
  box-shadow: none;
  animation: slide-right 1.5s ease-out;
}

@keyframes slide-right {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  60% {
    transform: translateX(5%);
    opacity: 0.8;
  }
  80% {
    transform: translateX(-2%);
    opacity: 0.9;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes zoom-in {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Modelos Disponibles */
.modelos-disponibles h2 {
  font-size: 45px;
  color: black;
  margin-left: 1.7em;
}

.modelos-disponibles {
  padding: 2rem;
  margin-top: -320px;
}

.car-container {
  overflow: hidden;
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.car-card {
  background: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(35, 22, 22, 0.2);
  text-align: center;
  height: 330px;
  width: 260px;
  margin: 0 auto;
  transition: transform 0.3s ease;
}

.car-card:hover {
  transform: scale(1.03);
}

.car-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.car-info img {
  width: 110px;
  height: auto;
}

.car-info h2 {
  font-size: 25px;
  color: #333;
  margin: 20px auto 0;
}

.detalle-button {
  background-color: #46495a;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 7px 20px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 30px;
}

.detalle-button:hover {
  background-color: #302642;
  transform: translateY(-2px);
  transition: transform 0.3s ease;
}

/* Botones de Navegación Personalizados */
.custom-prev,
.custom-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-size: 28px 28px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  z-index: 100;
}

.custom-prev {
  left: -50px;
  background-image: url("../../../../public/flechaIzquierda.png");
}

.custom-next {
  right: -50px;
  background-image: url("../../../../public/flechaDerecha.png");
}

.custom-prev:hover,
.custom-next:hover {
  transform: translateY(-50%) scale(1.2);
  transition: transform 0.5s ease;
}

/* Volteo de Tarjeta de Vehículos */
.car-card {
  perspective: 1000px;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-inner.flipped {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  top: 0;
  left: 0;
}

.card-back {
  transform: rotateY(180deg);
  background: #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

/* Ajustes para dispositivos móviles y otros breakpoints */
@media (max-width: 1360px) {
  .user-session {
    margin-left: 500px;
  }
  .detalle-button {
    margin-top: 0px;
  }
}

@media (max-width: 768px) {
  .swiper-button-prev {
    position: relative;
    left: -10%;
  }
  .home-container {
    justify-content: space-around;
  }
  .header-logo {
    display: block;
    position: relative;
    justify-content: start;
    margin-left: -60%;
  }
  .header-container {
    justify-content: space-between;
  }
  .user-session {
    margin-left: 0px;
  }
  .vehiculo-carousel-container {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin-top: 10em;
    margin-left: 4.5em;
  }
  .vehiculo-carousel {
    justify-content: center;
    width: 100vw;
    max-height: calc(90vw * 9 / 16);
    margin-right: 0;
  }
  .vehiculo-carousel-image {
    height: 90%;
    width: 90%;
  }
  .modelos-disponibles {
    position: relative;
    top: 360px;
    left: 15px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .modelos-disponibles h2 {
    position: relative;
    font-size: 45px;
    left: 13%;
  }
  .car-info h2 {
    position: relative;
    left: 0%;
  }
  .modelos-disponibles button {
    margin-top: 40px;
  }
  .header-logo {
    width: 170px;
  }
  .container-instagram {
    height: 3em;
    width: 3em;
  }
  .header-icono-instagram {
    width: 2.1em;
    height: 2.1em;
  }
  .detalle-button {
    font-size: 0.9em;
  }
}

@media (max-width: 600px) {
  .vehiculo-carousel-container {
    margin-top: 0em;
    margin-left: 4.5em;
    margin-bottom: -200px;
  }
  .vehiculo-carousel {
    justify-content: center;
    width: 100vw;
    max-height: calc(90vw * 9 / 16);
    margin-right: 0;
    margin-top: -200px;
  }
  .vehiculo-carousel-image {
    height: 90%;
    width: 90%;
  }
  .modelos-disponibles {
    top: 460px;
  }
  .modelos-disponibles h2 {
    left: 1%;
  }
}

@media (max-width: 480px) {
  .modelos-disponibles {
    position: relative;
    top: 410px;
    font-size: 1em;
    margin-left: 0em;
    margin-right: 0;
    justify-content: center;
    text-align: center;
  }
  .modelos-disponibles h2 {
    font-size: 35px;
    left: -6%;
  }
  .swiper-button-next {
    margin-right: 20px;
  }
  .user-session {
    margin-left: -70px;
  }
  .vehiculo-carousel {
    margin-top: -270px;
  }
}

@media (max-width: 479px) and (min-width: 421px) {
  .vehiculo-carousel {
    margin-top: -600px;
    margin-left: -50px;
  }
  .modelos-disponibles {
    top: 100px;
  }
}

@media (max-width: 420px) {
  .header-container .header-logo {
    display: block;
    position: relative;
    justify-content: start;
    margin-left: -60%;
  }
  .vehiculo-carousel-container {
    margin-top: 0em;
    margin-bottom: 0;
    height: 500px;
    margin-left: 50px;
  }
  .modelos-disponibles {
    position: relative;
    margin-top: -250px;
    margin-bottom: 50px;
  }
  .modelos-disponibles h2 {
    font-size: 2.3em;
    margin-left: 25px;
  }
}

@media (max-width: 419px) and (min-width: 360px) {
  .vehiculo-carousel-container {
    margin-top: 0;
    margin-bottom: 0;
    height: 500px;
    margin-left: 50px;
  }
  .modelos-disponibles {
    position: relative;
    margin-top: -250px;
    margin-bottom: 50px;
  }
  .modelos-disponibles h2 {
    font-size: 2.3em;
    margin-left: 25px;
  }
}

@media (max-width: 375px) {
  .vehiculo-carousel-container {
    margin-top: 0;
    margin-bottom: 20px;
    height: 500px;
    margin-left: 50px;
  }
  .modelos-disponibles {
    position: relative;
    margin-top: -250px;
  }
  .modelos-disponibles h2 {
    font-size: 2.3em;
    margin-left: 25px;
  }
}

@media (max-width: 360px) {
  /* Ajustes adicionales para pantallas muy pequeñas */
}
