/* Contenedor principal */
.values-container {
    gap: 8rem;           /* Espacio entre tarjetas */
    padding: 2rem;       /* Espacio interno */
    /* background: linear-gradient(135deg, #f0f0f0, #ffffff); */
    display: flex;
    justify-content: space-between;
    border-radius: 30px;
    width: 90%;
    height: auto;
    margin: 0 auto;       /* Centrar horizontalmente */
    margin-top: 5em;
    margin-bottom: 5em;
  }
  
  /* Tarjeta individual */
  .value-card {
    background-color: #fff;
    width: 250px;         /* Ancho fijo o ajustable */
    height: 250px;
    padding: 3rem;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); /* Sombra suave */
    border-radius: 50%;   /* Bordes redondeados (forma circular) */
    text-align: center;   /* Centra el texto */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Efecto hover en la tarjeta */
  .value-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }
  
  /* Envoltura para centrar el ícono */
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem; /* Espacio debajo del ícono */
  }
  
  /* Ícono */
  .value-icon {
    width: 100px; /* Ajusta el tamaño del ícono */
    height: 100px;
    transition: filter 0.3s ease;
    margin-top: 20px;
  }
  
  /* Efecto hover sobre el ícono */
  .value-card:hover .value-icon {
    filter: brightness(1.2);
  }
  
  /* Título */
  .value-title {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #46495a; /* Color acorde a la paleta */
  }
  
  /* Descripción */
  .value-description {
    font-size: 0.95rem;
    color: #666;
    line-height: 1.4;
  }
  
  /* ==================== MEDIA QUERIES ==================== */
  
  /* Pantallas medianas (tablets) */
  @media (max-width: 768px) {
    .values-container {
      /* Las tarjetas se apilan en columna */
      flex-direction: column;
      align-items: center;
      gap: 3rem;        /* Reduce el espacio entre tarjetas */
      margin-top: 2em;
      margin-bottom: 2em;
    }
  
    .value-card {
      width: 220px;     /* Reduce ligeramente el ancho */
      height: 220px;
      margin: 0 auto;   /* Centra cada tarjeta */
      padding: 2rem;    /* Ajusta el relleno interno */
      border-radius: 50%;
    }
  
    .value-icon {
      width: 80px;
      height: 80px;
      margin-top: 10px;
    }
  }
  
  /* Pantallas pequeñas (móviles) */
  @media (max-width: 480px) {
    .values-container {
      gap: 2rem;        /* Espacio menor entre tarjetas */
      padding: 1rem;    /* Menor padding */
    }
  
    .value-card {
      width: 180px;     /* Ancho más pequeño */
      height: 180px;
      padding: 1.5rem;  /* Menor padding interno */
    }
  
    .value-icon {
      width: 60px;
      height: 60px;
    }
  
    .value-title {
      font-size: 1.2rem;
    }
  
    .value-description {
      font-size: 0.9rem;
    }
  }
  