.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Gradiente en tonos morados/grises */
    background: linear-gradient(135deg, #302642, #46495a);
  }
  
  .login-card {
    background: #f8f8f8; /* Fondo ligeramente gris */
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    animation: slideIn 0.5s ease-in-out;
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-30px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .login-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #46495a; /* Texto principal */
    text-align: center;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group label {
    font-size: 16px;
    color: #46495a; /* Color del texto del label */
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  .form-group input {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    transition: border-color 0.3s ease;
  }
  
  .form-group input:focus {
    border-color: #46495a; /* Borde al enfocar */
    outline: none;
  }
  
  .password-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .toggle-password {
    font-size: 14px;
    background: none;
    border: none;
    color: #46495a; /* Color para el botón "mostrar/ocultar" */
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .toggle-password:hover {
    color: #302642;
  }
  
  .login-button {
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #46495a; /* Botón principal */
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .login-button:hover {
    background-color: #302642;
    transform: scale(1.05);
  }
  
  .redirect-text {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
    color: #555;
  }
  
  .redirect-link {
    color: #46495a; /* Link a juego con la paleta */
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    transition: color 0.3s ease;
  }
  
  .redirect-link:hover {
    color: #302642;
  }
  
  .back-button {
    margin-top: 20px;
    padding: 10px 15px;
    font-size: 14px;
    background: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    color: #46495a;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
  }
  
  .back-button:hover {
    background: #e9ecef;
    color: #302642;
  }
  