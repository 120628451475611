.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #ff9a9e, #fad0c4);
}

.register-card {
    background: #ffffff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    animation: slideIn 0.5s ease-in-out;
}

@keyframes slideIn {
    from {
        transform: translateY(-30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.register-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.register-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group label {
    font-size: 16px;
    color: #666;
    font-weight: 500;
    margin-bottom: 5px;
}

.form-group input {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    transition: border-color 0.3s ease;
}

.form-group input:focus {
    border-color: #e91e63;
    outline: none;
}

.password-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.toggle-password {
    font-size: 14px;
    background: none;
    border: none;
    color: #e91e63;
    cursor: pointer;
    transition: color 0.3s ease;
}

.toggle-password:hover {
    color: #c2185b;
}

.register-button {
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #e91e63;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.register-button:hover {
    background-color: #c2185b;
    transform: scale(1.05);
}

.redirect-text {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
    color: #555;
}

.redirect-link {
    color: #e91e63;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    transition: color 0.3s ease;
}

.redirect-link:hover {
    color: #c2185b;
}

.back-button {
    margin-top: 20px;
    padding: 10px 15px;
    font-size: 14px;
    background: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    color: #333;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
}

.back-button:hover {
    background: #e9ecef;
    color: #e91e63;
}
