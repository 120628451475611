/* HEADER FIJO ARRIBA */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;                /* Ocupa todo el ancho */
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Espacio entre logo y derecha */
  padding: 10px 20px;
  background: linear-gradient(135deg, #f5f7facf, #9ea9b9ab);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background 1s ease, backdrop-filter 1s ease;
  overflow: hidden;   /* Para escritorio */
}

.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #eaf2ffdc, #8996b0ce);
  opacity: 0;
  transition: opacity 0.5s ease; /* Controla la velocidad de la transición */
  z-index: -1; /* Ubica el pseudo-elemento detrás del contenido */
}

.header:hover::before {
  opacity: 1;
}

/* LOGO */
.header-left {
  display: flex;
  align-items: center;
}
.header-logo {
  height: 50px;
  transition: transform 0.3s ease;
  cursor: pointer;
}
.header-logo:hover {
  transform: scale(1.1);
}

/* NAVBAR CENTRADO (solo visible en escritorio) */
.header-center {
  flex: 1;  
  display: flex;
  justify-content: center;
  align-items: center;
}

/* DERECHA: usuario, redes, menú móvil */
.header-right {
  display: flex;
  align-items: center;
  gap: 1rem; 
}

/* USUARIO + BOTÓN LOGOUT */
.user-logout-container {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.user-session {
  font-size: 16px;
  color: #333;
  margin: 0;
}
.logout-button {
  padding: 5px 10px;
  font-size: 0.9rem;
  font-weight: bold;
  border: 1px solid #000;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}
.logout-button:hover {
  background-color: #f0f0f0;
  transform: scale(1.03);
}

/* REDES SOCIALES */
.container-redes-sociales {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 60px;
}
.container-instagram {
  display: flex;
  align-items: center;
  border: 2.5px solid black;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}
.container-instagram:hover {
  transform: scale(1.05);
  background-color: #e3e3e3a8;
}

/* MENÚ HAMBURGUESA (oculto en escritorio, visible en móvil) */
.mobile-menu-toggle {
  display: none;  
  cursor: pointer;
  transition: transform 0.3s ease;
  border: 2px solid black;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 5px;
  background: #fff;
}
.mobile-menu-toggle:hover {
  transform: scale(1.1);
}

/* MEDIA QUERIES */
@media (max-width: 768px) {
  .header-logo {
    height: 40px;
    margin-left: -30px;
  }
  .container-redes-sociales {
    margin-left: -10px;
  }

  /* Mostrar botón hamburguesa en móvil */
  .mobile-menu-toggle {
    display: block;
    margin-right: 40px;
  }

  /* Opcional: ocultar navbar centrado en móvil (si se usa menú hamburguesa) */
  .header-center {
    display: none;
  }
}

@media (max-width: 480px) {
  .header-logo {
    height: 35px;
  }
  .container-instagram {
    width: 30px;
    height: 30px;
  }
  .container-instagram svg {
    width: 20px;
    height: 20px;
  }
}