footer {
    padding: 20px;
    background-color: #4d578a;
    height: auto;
    color: white;
  }
  
  .footer-superior {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1em;
    margin-top: 2em;
    background-color: #46425f00;
    flex-wrap: wrap; /* Permite que los elementos se envuelvan en pantallas pequeñas */
  }
  
  .footer-section {
    padding: 10px;
    text-align: left;
    color: white;
  }
  
  .footer-section-1 {
    width: 25em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-section-2,
  .footer-section-3,
  .footer-section-4 {
    width: 20%;
  }
  
  .footer-logo img {
    width: auto;
    height: 8em;
  }
  
  .footer-logo img:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
  
  .footer-rrss {
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }
  
  .rrss-icon {
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background-color: white;
    margin: 0 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .rrss-icon img {
    width: 70%;
    height: 70%;
  }
  
  .rrss-icon:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .footer-section .footer-titulo {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
  }
  
  .footer-section h4 {
    font-size: 1em;
    cursor: pointer;
    margin: 0.5em 0;
    position: relative;
  }
  
  .footer-section h4:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  
  .footer-icon {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
  
  .footer-section button {
    width: 9em;
    height: 3em;
    padding: 5px 10px;
    background-color: #6d7fd0;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 0.8em;
    font-weight: bold;
    margin-top: 1em;
  }
  
  .footer-section button:hover {
    background-color: #302642;
    transform: translateY(-2px);
    transition: transform 0.3s ease;
  }
  
  .footer-inferior {
    text-align: center;
    padding: 10px;
    border-top: 2px solid white;
    margin-top: 3.5em;
    order: 5; /* Asegura que el footer inferior esté al final */
  }
  
  .footer-inferior p {
    margin-top: 1em;
    margin-bottom: -0.3em;
    font-weight: bold;
    font-size: 1em;
  }
  
  .medios-pago {
    margin-top: 5px;
    height: 50px;
    width: 100px;
  }
  
  .footer-section h3::after,
  .footer-section h4::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background-color: white;
    bottom: -5px;
    left: 0;
    transition: width 0.3s ease;
    z-index: 999;
  }
  
  .footer-section h3:hover::after,
  .footer-section h4:hover::after {
    width: 90%;
  }
  
  /* Estilos para dispositivos móviles */
  @media (max-width: 768px) {
    .footer-superior {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section-1 {
      order: 1; /* Sección 1 arriba */
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
  
    .footer-section-2,
    .footer-section-3 {
      width: 45%; /* Sección 2 y 3 al lado */
      display: inline-block;
      text-align: center;
      margin-bottom: 10px;
    }
  
    .footer-section-2 {
      order: 2; /* Sección 2 al lado de la 3 */
    }
  
    .footer-section-3 {
      order: 3; /* Sección 3 al lado de la 2 */
    }
  
    .footer-section-4 {
      order: 4; /* Sección 4 debajo de la 2 y 3 */
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
  
    .footer-inferior {
      order: 5; /* Footer inferior al final */
    }
  
    .footer-logo img {
      height: 5em;
    }
  
    .footer-section h3::after,
    .footer-section h4::after {
      left: 38%;
    }
  
    .footer-section h3:hover::after,
    .footer-section h4:hover::after {
      width: 30%;
      text-align: center;
    }
  }
  
  @media (max-width: 480px) {
    .footer-superior {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section-1,
    .footer-section-2,
    .footer-section-3,
    .footer-section-4 {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .footer-inferior p {
      font-size: 0.8em;
    }
  
    .rrss-icon {
      width: 2em;
      height: 2em;
    }
  
    .footer-rrss {
      width: 15em;
    }
  }