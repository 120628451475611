body {
  margin: 0;
  padding-top: 81px; /* Ajusta según la altura de tu header */
  box-sizing: border-box;
  background-color: #ffffff;
}

/* Navbar General */
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* Se quita el margin-left negativo */
  margin-left: 0;
}

/* Contenido del Navbar */
.navbar-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

/* Enlaces del Navbar */
.navbar-content a {
  margin: 0 5px;
  padding: 10px 15px;
  text-decoration: none;
  color: #000000;
  font-size: 1em;
  font-weight: bold;
  background-color: #fff;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.navbar-content a:hover {
  color: #000000;
  transform: scale(1.05);
  background-color: #d5dde9ab;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.194);
}

/* Efecto hover en el botón de menú móvil */
.mobile-menu-toggle:hover {
  transform: scale(1.05);
  background-color: #a8a7a7a8;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

/* NAVBAR DESPLEGABLE EN MÓVILES */
@media (max-width: 768px) {
  body {
    padding-top: 0;
  }
  /* Mostramos el botón hamburguesa en móvil */
  .mobile-menu-toggle {
    display: block;
    cursor: pointer;
    border: 2px solid black;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 5px;
    background: #fff;
    transition: transform 0.3s ease;
  }

  .navbar {
    background-color: #fff;
    position: absolute;
    top: 100%;       /* Debajo del Header */
    left: 0;         /* Ocupa todo el ancho */
    width: 100%;
    flex-direction: column;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    z-index: 999;    /* Sobre el contenido */
  }

  /* Cuando se abre el menú */
  .navbar.open {
    max-height: 300px; /* Ajusta según contenido */
    opacity: 1;
  }

  .navbar-content {
    flex-direction: column;
    padding: 20px;
    width: 100%;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
  }

  .navbar-content a {
    margin-bottom: 20px;
    padding: 10px 10px;
    width: 250px;
    text-align: center;
  }

  .navbar-content a:hover {
    background: #f0f0f0;
    transform: scale(1.02);
  }
}

/* Ajustes adicionales en breakpoints menores */
@media (max-width: 600px) {
  .mobile-menu-toggle {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 480px) {
  .mobile-menu-toggle {
    width: 25px;
    height: 25px;
  }
  .mobile-menu-toggle * {
    height: 15px;
    width: 20px;
  }
}

/* HEADER FIJO ARRIBA */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;                /* Ocupa todo el ancho */
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Espacio entre logo y derecha */
  padding: 10px 20px;
  background: linear-gradient(135deg, #f5f7facf, #9ea9b9ab);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background 1s ease, backdrop-filter 1s ease;
  overflow: hidden;   /* Para evitar que el pseudo-elemento se salga del contenedor */
}

.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #eaf2ffdc, #8996b0ce);
  opacity: 0;
  transition: opacity 0.5s ease; /* Controla la velocidad de la transición */
  z-index: -1; /* Ubica el pseudo-elemento detrás del contenido */
}

.header:hover::before {
  opacity: 1;
}

/* LOGO */
.header-left {
  display: flex;
  align-items: center;
}
.header-logo {
  height: 50px;
  transition: transform 0.3s ease;
  cursor: pointer;
  margin-left: 20px;
}
.header-logo:hover {
  transform: scale(1.05);
}

/* NAVBAR CENTRADO (solo visible en escritorio) */
.header-center {
  flex: 1;  
  display: flex;
  justify-content: center;
  align-items: center;
}

/* DERECHA: usuario, redes, menú móvil */
.header-right {
  display: flex;
  align-items: center;
  gap: 1rem; 
}

/* USUARIO + BOTÓN LOGOUT */
.user-logout-container {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.user-session {
  font-size: 16px;
  color: #333;
  margin: 0;
}
.logout-button {
  padding: 5px 10px;
  font-size: 0.9rem;
  font-weight: bold;
  border: 1px solid #000;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}
.logout-button:hover {
  background-color: #f0f0f0;
  transform: scale(1.03);
}

/* REDES SOCIALES */
.container-redes-sociales {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 60px;
}
.container-instagram {
  display: flex;
  align-items: center;
  border: 2.5px solid black;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}
.container-instagram:hover {
  transform: scale(1.05);
  background-color: #e3e3e3a8;
}

/* MENÚ HAMBURGUESA (oculto en escritorio, visible en móvil) */
.mobile-menu-toggle {
  display: none;  
  cursor: pointer;
  transition: transform 0.3s ease;
  border: 2px solid black;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 5px;
  background: #fff;
}
.mobile-menu-toggle:hover {
  transform: scale(1.1);
}

/* MEDIA QUERIES */
@media (max-width: 768px) {
  .header-logo {
    height: 40px;
    margin-left: -30px;
  }
  .container-redes-sociales {
    margin-left: -10px;
  }

  /* Mostrar botón hamburguesa en móvil */
  .mobile-menu-toggle {
    display: block;
    margin-right: 40px;
  }

  /* Opcional: ocultar navbar centrado en móvil (si se usa menú hamburguesa) */
  .header-center {
    display: none;
  }
}

@media (max-width: 480px) {
  .header-logo {
    height: 35px;
  }
  .container-instagram {
    width: 30px;
    height: 30px;
  }
  .container-instagram svg {
    width: 20px;
    height: 20px;
  }
}