/* Contenedor principal */
.nuestra-empresa {
  display: flex;
  flex-direction: column;
  gap: 3rem; /* Menor espacio entre secciones */
  padding: 2rem 1rem; /* Menor padding para aprovechar más el ancho */
}

/* Sección individual (zigzag) */
.nosotros-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem; /* Espacio reducido entre texto e imagen */
  padding: 1.5rem; /* Menor padding interno */
  /* background: linear-gradient(135deg, #f0f0f0 0%, #ffffff 100%); */
  animation: fadeSlide 0.8s ease;
  margin: 0 auto; /* Centrar en pantallas grandes */
}

/* Invierte el orden para secciones alternas */
.nosotros-section.reverse {
  flex-direction: row-reverse;
}

/* Animación de entrada */
@keyframes fadeSlide {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Contenedor del texto: usa un 60% del ancho disponible */
.nosotros-content {
  flex: 0 0 60%;
  text-align: center;
}

/* Título */
.nosotros-title {
  font-size: 45px;
  color: #46495a;
  margin-bottom: 1rem;
  font-weight: 700;
}

/* Descripción */
.nosotros-description {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

/* Botón (opcional) */
.nosotros-button {
  padding: 0.8rem 1.5rem;
  background-color: #46495a;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.nosotros-button:hover {
  background-color: #302642;
  transform: scale(1.03);
}

/* Contenedor de la imagen: usa un 40% del ancho disponible */
.nosotros-image-container {
  flex: 0 0 60%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
}

/* Efecto de zoom en la imagen */
.nosotros-image-container:hover .nosotros-image {
  transform: scale(1.05);
}

/* Imagen */
.nosotros-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* Responsive: en pantallas pequeñas, apilar en columna */
@media (max-width: 768px) {
  .nosotros-section {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }
  .nosotros-section.reverse {
    flex-direction: column;
  }
  .nosotros-content,
  .nosotros-image-container {
    flex: none;
    width: 100%;
    max-width: 100%;
  }
  .nosotros-image-container {
    margin-top: 1rem;
  }
} 