/* Superposición oscura que cubre toda la pantalla */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Oscurece un poco el fondo */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: fadeIn 0.3s ease forwards;
}

/* Contenido del modal */
.modal-content {
  background-color: #b1b1b1;
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  color: #222;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: slideUp 0.4s ease forwards;
}

/* Cabecera del modal */
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

/* Botón de cierre (X) */
.close-button {
  cursor: pointer;
  font-size: 1.2rem;
  color: #333;
  transition: transform 0.3s ease;
}

.close-button:hover {
  transform: scale(1.1);
}

/* Cuerpo del modal */
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
}

/* Íconos */
.icon {
  font-size: 1.2rem;
}

/* Animaciones */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
