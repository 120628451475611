.admin-dashboard {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dashboard-header h1 {
  font-size: 24px;
  color: #333;
}

.dashboard-header h2 {
  font-size: 18px;
  color: #666;
}

.logout-button,
.back-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #d9534f;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.logout-button:hover,
.back-button:hover {
  background-color: #c9302c;
}

.dashboard-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.nav-button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.nav-button.active {
  background-color: #0056b3;
}

.admin-form {
  width: 700px;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  margin: 0 auto;
}

.admin-form input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #5cb85c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #4cae4c;
}

.car-list1 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.car-card1 {
  background: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(35, 22, 22, 0.2);
  text-align: center;
  height: 460px;
  width: 400px;
  margin: 20px 100px;
}

.car-image1 {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.car-info1 {
  justify-content: space-around;
  display: flex;
  flex-direction: row;
}

.car-info1 img {
  width: 110px;
  height: auto;
}

.car-info1 h2 {
  font-size: 25px;
  color: #333;
  margin-right: 60px;
}

@media (max-width: 1360px) {
  .car-card {
    height: 400px;
    width: 350px;
    margin-bottom: 10px;
    margin: 10px 20px;
  }
}

@media (max-width: 768px) {
  .car-card {
    height: 340px;
    width: 320px;
    margin-bottom: 10px;
    margin: 10px 20px;
  }

  .car-info {
    justify-content: space-around;
    display: flex;
    flex-direction: row;
    margin-bottom: -50px;
  }

  .car-info img {
    width: 70px;
    height: auto;
  }

  .car-info h2 {
    font-size: 20px !important;
    color: #333;
    margin-right: 40px;
  }
}

@media (max-width: 600px) {
  .car-card {
    width: 270px;
    height: 330px;
    margin: 10px 5px 10px 4px;
  }
}

@media (max-width: 480px) {
  .car-card {
    position: relative;
    left: 20%;
    height: 320px;
  }
}
@media (max-width: 479px) and (min-width: 421px) {
  .car-card {
    position: relative;
    left: 15%;
    height: 320px;
  }
}

@media (max-width: 420px) {
  .car-card {
    position: relative;
    height: 320px;
    width: 280px;
    left: 13%;
  }
}

@media (max-width: 375px) {
  .car-card {
    position: relative;
    height: 320px;
    width: 280px;
    left: 8%;
  }
}

.edit-button {
  padding: 10px;
  font-size: 14px;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 5%;
}

.edit-button,
.delete-button {
  padding: 7px;
  font-size: 14px;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 5%;
}

.edit-button {
  background-color: #0275d8;
}

.edit-button:hover {
  background-color: #025aa5;
}

.delete-button {
  background-color: #d9534f;
}

.delete-button:hover {
  background-color: #c9302c;
}

.carousel-section {
  margin-top: 20px;
}

.add-carousel {
  width: 700px;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin: 0 auto;
}

.add-carousel h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
}

.add-carousel input {
  width: calc(100% - 20px);
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.add-carousel .add-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #5cb85c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #4cae4c;
}

.carousel-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 60px;
}

.carousel-image1 {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.carousel-image1:hover {
  transform: scale(1.05);
}

.carousel-card {
  text-align: center;
}

.carousel-card img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.edit-button,
.update-button {
  background-color: #0275d8;
}

.cancel-button {
  background-color: #d9534f;
}

.nosotros-section {
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  text-align: center;
}

.nosotros-input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.nosotros-textarea {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 100px;
}

.nosotros-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.nosotros-image-card {
  background: white;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.nosotros-card-image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.nosotros-input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.save-button {
  background-color: #5cb85c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.save-button:hover {
  background-color: #4cae4c;
}

.dashboard-comentarios-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
}

.comentarios-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  padding: 20px 0;
}

.comentario-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
}

.comentario-imagen {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.comentario-info h3 {
  font-size: 18px;
  color: #ffcc00;
  margin-bottom: 5px;
}

.comentario-info p {
  font-size: 14px;
  color: #333;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}
y aca:html {
  scroll-behavior: smooth;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-catalogo {
  animation: slideInRight 0.5s ease-out;
}

/* Carrusel */
.vehiculo-carousel-container {
  width: 70vw; /* Más pequeño que 80vw */
  height: 80vh; /* Más pequeño que 90vh */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px; /* Se mantiene para conservar la posición vertical */
  animation: slide-up 0.5s ease-in;
}

.vehiculo-carousel {
  display: flex;
  position: absolute; /* Se mantiene la posición absoluta */
  overflow: hidden;
  border-radius: 15px;
  width: 50vw; /* Más pequeño que 70vw */
  max-height: calc(50vw * 9 / 16); /* Proporción 16:9 */
  margin-top: -400px; /* Se mantiene para conservar la posición vertical */
  transition: transform 0.3s ease-in-out;
}

.vehiculo-carousel:hover {
  transform: scale(1.03);
}

.vehiculo-carousel-image {
  width: calc(100% - 10px); /* 10px menos que el contenedor */
  height: calc(100% - 10px); /* 10px menos que el contenedor */
  object-fit: cover; /* Ajusta la imagen sin distorsión */
  display: block;
  transition: translate 1s ease-in-out;
  border: none;
  box-shadow: none;
  animation: slide-right 1.5s ease-out;
}

@keyframes slide-right {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  60% {
    transform: translateX(5%);
    opacity: 0.8;
  }
  80% {
    transform: translateX(-2%);
    opacity: 0.9;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes zoom-in {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Modelos Disponibles */
.modelos-disponibles h2 {
  font-size: 45px;
  color: black;
  margin-left: 1.7em;
}

.modelos-disponibles {
  position: relative;
  overflow: visible;
  padding: 2rem;
  margin-top: -320px;
}

/* Contenedor extra para el Swiper */
.swiper-outer-wrapper {
  position: relative;
  overflow: visible;
  width: 90%;
  margin: 0 auto;
}

/* Estilos de las tarjetas */
.car-card {
  background: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(35, 22, 22, 0.2);
  text-align: center;
  height: 330px;
  width: 260px;
  margin: 0 auto;
  transition: transform 0.3s ease;
}

.car-card:hover {
  transform: scale(1.03);
}

.car-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.car-info img {
  width: 110px;
  height: auto;
}

.car-info h2 {
  font-size: 25px;
  color: #333;
  margin: 0 auto;
  margin-top: 20px;
}

.detalle-button {
  background-color: #46495a;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 7px 20px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
  position: relative;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 30px;
}

.detalle-button:hover {
  background-color: #302642;
  transform: translateY(-2px);
  transition: transform 0.3s ease;
}

/* --- Personalización de las flechas nativas de Swiper --- */

/* Oculta el contenido por defecto */
.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

/* Flechas posicionadas absolutamente dentro del contenedor extra */
.swiper-outer-wrapper .swiper-button-prev,
.swiper-outer-wrapper .swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;  /* Tamaño de la flecha */
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 1000; /* Muy alto para que queden encima */
}

/* Flecha izquierda: se ubica hacia la izquierda del wrapper */
.swiper-outer-wrapper .swiper-button-prev {
  left: -80px; /* Valor negativo para sacarla fuera del contenedor */
  background-image: url("../../../../public/flechaIzquierda.png");
}

/* Flecha derecha: se ubica hacia la derecha del wrapper */
.swiper-outer-wrapper .swiper-button-next {
  right: -80px; /* Valor negativo para sacarla fuera del contenedor */
  background-image: url("../../../../public/flechaDerecha.png");
}

/* Efecto hover para flechas */
.swiper-outer-wrapper .swiper-button-prev:hover,
.swiper-outer-wrapper .swiper-button-next:hover {
  transform: translateY(-50%) scale(1.1);
  transition: transform 0.3s ease;
}

/* Estilos de la animación de flip (sin cambios) */
.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-inner.flipped {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  top: 0;
  left: 0;
}

.card-back {
  transform: rotateY(180deg);
  background: #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}



@media (max-width: 1360px) {
  .user-session {
    margin-left: 500px;
  }

  .detalle-button {
    margin-top: 0px;
  }
}

@media (max-width: 768px) {
  .swiper-button-prev {
    position: relative;
    left: -10%;
  }

  .home-container {
    justify-content: space-around;
  }

  .header-logo {
    display: block;
    position: relative;
    justify-content: start;
    margin-left: -60%;
  }

  .header-container {
    justify-content: space-between;
  }

  .user-session {
    margin-left: 0px;
  }

  .vehiculo-carousel-container {
    justify-content: center;
    align-items: center;
    margin-bottom: -0px;
    margin-top: 10em;
    margin-left: 4.5em;
  }

  .vehiculo-carousel {
    justify-content: center;
    width: 100vw; /* Ajusta el ancho al contenedor */
    max-height: calc(90vw * 9 / 16); /* Mantiene una proporción de 16:9 */
    margin-right: 0;
  }

  .vehiculo-carousel-image {
    height: 90%; /* Ajusta la altura deseada */
    width: 90%;
  }

  .modelos-disponibles {
    position: relative;
    top: 360px;
    left: 15px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .modelos-disponibles h2 {
    position: relative;
    font-size: 45px;
    left: 13%;
  }

  .car-info h2 {
    position: relative;
    left: 0%;
  }

  .modelos-disponibles button {
    margin-top: 40px;
  }

  .header-logo {
    width: 170px; /* Reduce aún más el tamaño del logo */
  }

  .container-instagram {
    height: 3em; /* Ajusta el tamaño del contenedor */
    width: 3em; /* Ajusta el tamaño del contenedor */
  }

  .header-icono-instagram {
    width: 2.1em;
    height: 2.1em;
  }

  .detalle-button {
    font-size: 0.9em;
  }
}

@media (max-width: 600px) {
  .vehiculo-carousel-container {
    margin-top: 0em;
    margin-left: 4.5em;
    margin-bottom: -200px;
  }

  .vehiculo-carousel {
    justify-content: center;
    width: 100vw; /* Ajusta el ancho al contenedor */
    max-height: calc(90vw * 9 / 16); /* Mantiene una proporción de 16:9 */
    margin-right: 0;
    margin-top: -200px;
  }

  .vehiculo-carousel-image {
    height: 90%; /* Ajusta la altura deseada */
    width: 90%;
  }

  .modelos-disponibles {
    top: 460px;
  }

  .modelos-disponibles h2 {
    left: 1%;
  }
}

@media (max-width: 480px) {
  .modelos-disponibles {
    position: relative;
    top: 410px;
    font-size: 1em;
    margin-left: 0em;
    margin-right: 0;
    justify-content: center;
    text-align: center;
  }

  .modelos-disponibles h2 {
    font-size: 35px;
    left: -6%;
  }

  .swiper-button-next {
    margin-right: 20px;
  }

  .user-session {
    margin-left: -70px;
  }

  .vehiculo-carousel {
    margin-top: -270px;
  }
}
@media (max-width: 479px) and (min-width: 421px) {
  .vehiculo-carousel {
    margin-top: -600px;
    margin-left: -50px;
  }

  .modelos-disponibles {
    top: 100px;
  }
}

@media (max-width: 420px) {
  .header-container .header-logo {
    display: block;
    position: relative;
    justify-content: start;
    margin-left: -60%;
  }

  .vehiculo-carousel-container {
    margin-top: 0em;
    margin-bottom: 0;
    height: 500px;
    margin-left: 50px;
  }

  .modelos-disponibles {
    position: relative;
    margin-top: -250px;
    margin-bottom: 50px;
  }

  .modelos-disponibles h2 {
    font-size: 2.3em;
    margin-left: 25px;
  }
}

@media (max-width: 419px) and (min-width: 360px) {
  .vehiculo-carousel-container {
    margin-top: 0;
    margin-bottom: 0;
    height: 500px;
    margin-left: 50px;
  }

  .modelos-disponibles {
    position: relative;
    margin-top: -250px;
    margin-bottom: 50px;
  }

  .modelos-disponibles h2 {
    font-size: 2.3em;
    margin-left: 25px;
  }
}

@media (max-width: 375px) {
  .vehiculo-carousel-container {
    margin-top: 0;
    margin-bottom: 20px;
    height: 500px;
    margin-left: 50px;
  }

  .modelos-disponibles {
    position: relative;
    margin-top: -250px;
  }

  .modelos-disponibles h2 {
    font-size: 2.3em;
    margin-left: 25px;
  }
}

@media (max-width: 360px) {
}
