/* Contenedor principal del formulario */
.cotiza-container {
  width: 370px; /* Ancho reducido para escritorio */
  max-width: 90%;
  padding: 20px;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2); /* Degradado moderno */
  border-radius: 20px; /* Bordes un poco más redondeados */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
  position: absolute;
  top: 50%;
  right: 8%;
  z-index: 10;
}

.cotiza-container:hover {
  transform: scale(1.02);
}

.cotiza-container h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
  margin-top: 2px;
}

.cotiza-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cotiza-form .form-group {
  width: 90%;
  margin-bottom: 10px;
}

.cotiza-form .form-group input {
  width: 90%;
  padding: 8px;
  border-radius: 15px;
  font-size: 0.9em;
  font-weight: bold;
  border: 1px solid #d1dede;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus {
  border-color: #46495a;
  box-shadow: 0 0 3px rgba(70, 73, 90, 0.5);
  outline: none;
}

.cotiza-button {
  align-self: flex-center;
  padding: 7px 70px;
  background-color: #46495a;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1em;
  margin-top: 5px;
}

.cotiza-button:hover {
  background-color: #37373d;
  transform: translateY(-2px);
}

/* Contenedor del selector */
.selector-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
}

.selector {
  width: 95%;
  padding: 8px 12px;
  font-size: 0.9em;
  font-weight: bold;
  color: #717171;
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid #d1dede;
  border-radius: 15px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.selector:hover {
  border-color: #46495a;
  background-color: rgba(255, 255, 255, 1);
}

.selector::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(100, 149, 237, 0.3),
    rgba(147, 112, 219, 0.3)
  );
  transition: left 0.5s ease;
  z-index: -1;
}

.selector:hover::before {
  left: 0;
}

.selector-container::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 33px;
  transform: translateY(-50%);
  font-size: 0.8em;
  color: #46495a;
  pointer-events: none;
}

.selector:focus {
  outline: none;
  border-color: #302642;
  box-shadow: 0 0 8px rgba(48, 38, 66, 0.3);
}

.selector option {
  padding: 8px;
  background-color: #fff;
  color: #46495a;
  font-size: 0.9em;
  font-weight: normal;
}

/* Date input container */
.date-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.calendar-icon {
  position: absolute;
  right: 30px;
  font-size: 1.2rem;
  color: #46495a;
  cursor: pointer;
  transition: color 0.3s ease;
}

.calendar-icon:hover {
  color: #302642;
}

.hidden-date-input {
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
}

/* Error text styling */
.error-text {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

/* MEDIA QUERIES para vista escritorio no afectan esta versión, solo móviles */
@media (max-width: 768px) {
  .cotiza-container {
    /* Mantener la versión móvil sin cambios aquí */
    position: relative;
    justify-content: center;
    align-items: center;
    right: -5%;
    margin-top: 170%;
    top: calc(-50% - 5vh);
  }
  .cotiza-container h2 {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .cotiza-container {
    position: relative;
    justify-content: center;
    align-items: center;
    right: -1%;
    margin-top: 20px;
    top: 50%;
  }
}

@media (max-width: 480px) {
  .selector-container {
    max-width: 100%;
  }
  .selector {
    font-size: 0.9em;
    padding: 12px;
  }
  .cotiza-container {
    position: relative;
    right: 5%;
    width: 60%;
    max-width: 100%;
    margin: 2em auto;
    border-radius: 10px;
    top: 40%;
  }
}

@media (max-width: 479px) and (min-width: 421px) {
  .cotiza-container {
    position: absolute;
    max-width: 100vw;
    max-height: 80%;
    height: 320px;
    width: 300px;
    right: 11%;
    top: 57%;
  }
}

@media (max-width: 420px) {
  .cotiza-container {
    position: absolute;
    max-width: 100vw;
    max-height: 80%;
    height: 370px;
    width: 280px;
    right: 11%;
    top: 65%;
  }
}

@media (max-width: 419px) and (min-width: 361px) {
  .cotiza-container {
    position: absolute;
    max-width: 100vw;
    max-height: 80%;
    height: 370px;
    width: 280px;
    right: 10%;
    top: 45%;
  }
}

@media (max-width: 375px) {
  .cotiza-container {
    position: absolute;
    max-width: 100vw;
    max-height: 80%;
    height: 370px;
    width: 280px;
    right: 7%;
    top: 55%;
  }
}

@media (max-width: 360px) {
  .cotiza-container {
    position: absolute;
    max-width: 100vw;
    max-height: 80%;
    height: 370px;
    width: 280px;
    right: 5%;
    top: 22%;
  }
}
