.nosotros-father {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: auto;
  width: 85%; /* Disminuido ligeramente */
  margin: 0 auto;
  animation: slideFade 500ms;
}

@keyframes slideFade {
  /* Puedes definir la animación si es necesario */
}

.nosotros-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 auto;
}

.nosotros-section {
  width: 100%;
  height: 80%;
  border-radius: 8px; /* Radio reducido */
}

.nosotros-grid {
  display: grid;
  grid-template-columns: 5fr 3fr 3fr;
  gap: 10px; /* Espacio reducido */
}

.nosotros-column-1,
.nosotros-column-2,
.nosotros-column-3 {
  width: 95%;
  height: 100%;
}

/* Tarjetas de imagen */
.nosotros-image-card-1,
.nosotros-image-card-2,
.nosotros-image-card-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nosotros-image-card-1:hover,
.nosotros-image-card-2:hover,
.nosotros-image-card-3:hover {
  transition: background 0.3s ease-in-out, transform 0.3s ease;
  background: #5a5d6d;
  transform: scale(1.05);
}

/* Columna 1 */
.nosotros-column-1 {
  display: flex;
  flex-direction: column;
  gap: 8px; /* Menor espacio entre elementos */
  height: 100%;
}

.nosotros-image-card-1 {
  width: 100%;
  height: 75%; /* Disminuido para ser más compacto */
  border-radius: 8px; /* Menor radio */
  overflow: hidden;
  box-shadow: 0 3px 5px rgba(121, 120, 120, 0.8);
}

.nosotros-image-card-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.nosotros-column-1 h3 {
  margin-top: 0.8em;
  font-size: 45px;
  color: black;
  text-align: left;
  margin-bottom: 8px;
}

.nosotros-column-1 p {
  text-align: justify;
  font-size: 20px;
  color: #46495a;
  font-weight: 600;
  margin-top: -10px;
}

.nosotros-p-card-1 {
  font-size: 15px !important;
  text-align: left;
  font-weight: bold;
  height: 3em;
  width: 38%;
  margin-top: -5.5em;
  background-color: white;
  border-radius: 8px;
  margin-right: 12em;
  display: flex;
  align-items: center;
  padding: 8px;
}

/* Columna 2 */
.nosotros-column-2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.nosotros-image-card-2 {
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(121, 120, 120, 0.8);
}

.nosotros-image-card-2 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.nosotros-p-card-2 {
  font-size: 15px !important;
  text-align: left;
  font-weight: bold;
  height: 2.8em;
  width: 55%;
  margin-top: -5em;
  background-color: white;
  border-radius: 8px;
  margin-right: 5em;
  display: flex;
  align-items: center;
  padding: 8px;
}

/* Columna 3 */
.nosotros-column-3 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  justify-content: space-between;
}

.nosotros-image-card-3 {
  height: auto;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(121, 120, 120, 0.8);
}

.nosotros-image-card-3 img {
  width: 140%; /* Disminuido del 150% original */
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.nosotros-p-card-3 {
  font-size: 15px !important;
  text-align: left;
  font-weight: bold;
  height: 2.8em;
  width: 55%;
  margin-top: -5em;
  background-color: white;
  border-radius: 8px;
  margin-right: 5em;
  display: flex;
  align-items: center;
  padding: 8px;
}

/* Media query para pantallas de menos de 1200px */
@media (max-width: 1200px) {
  .nosotros-father {
    height: auto;
    width: 100%;
  }
  .nosotros-grid {
    display: flex;
    flex-direction: column;
  }
  .nosotros-column-3 {
    flex-direction: row;
    justify-content: center;
  }
  .nosotros-column-3 .nosotros-image-card-3 {
    flex: 1;
    margin: 8px;
  }
}

/* Media query para pantallas de menos de 768px */
@media (max-width: 768px) {
  .nosotros-father {
    margin-top: 250px;
  }
  .nosotros-section {
    width: 100%;
    position: relative;
  }
  .nosotros-p-card-3,
  .nosotros-p-card-2,
  .nosotros-p-card-1 {
    font-size: 14px !important;
    width: 42%;
    margin-right: 7em;
    margin-top: -6em;
  }
  .nosotros-image-card-1,
  .nosotros-image-card-2,
  .nosotros-image-card-3 {
    border-radius: 30px;
  }
  .nosotros-column-1,
  .nosotros-column-2,
  .nosotros-column-3 {
    margin-left: 15px;
  }
  .nosotros-column-3 {
    flex-direction: column;
    align-items: center;
  }
  .nosotros-column-3 .nosotros-image-card-3 {
    width: 100%;
    margin: 8px 0;
  }
  .nosotros-image-card-3 img {
    width: 100%;
    height: auto;
  }
  .nosotros-column-1 h3 {
    text-align: center;
  }
  .nosotros-column-1 p {
    text-align: center;
    font-size: 120%;
  }
}

/* Media query para pantallas de menos de 600px */
@media (max-width: 600px) {
  .nosotros-father {
    margin-top: 320px;
  }
}

/* Media query para pantallas de menos de 480px */
@media (max-width: 480px) {
  .nosotros-p-card-3,
  .nosotros-p-card-2,
  .nosotros-p-card-1 {
    font-size: 12px !important;
    width: 60%;
    margin-right: 8em;
  }
  .nosotros-column-1 h3 {
    margin-top: 20px;
    font-size: 2em;
  }
  .nosotros-column-1 p {
    font-size: 18px;
    text-align: justify;
  }
  .nosotros-column-1,
  .nosotros-column-2,
  .nosotros-column-3 {
    margin-left: 10px;
  }
  .nosotros-column-3 {
    flex-direction: column;
    align-items: center;
  }
  .nosotros-column-3 .nosotros-image-card-3 {
    width: 100%;
    margin: 8px 0;
  }
  .nosotros-image-card-3 img {
    width: 100%;
    height: auto;
  }
  .nosotros-father {
    height: auto;
    width: 100%;
    margin-top: 18em;
  }
}

/* Media query para pantallas de menos de 479px y mayor a 421px */
@media (max-width: 479px) and (min-width: 421px) {
  .nosotros-father {
    margin-top: 1em;
  }
}

/* Media query para pantallas de menos de 420px */
@media (max-width: 420px) {
  .nosotros-column-1 h3 {
    margin-top: 10px;
    font-size: 2em;
  }
  .nosotros-column-1 p {
    font-size: 18px;
    text-align: justify;
  }
}
