/* Carousel.css */

.main-carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;

  animation: fadeSlide 1s;
}

@keyframes fadeSlide {
  from {
    opacity: 0;
    transition: transform 0.5s ease-in;
    translate: -100%;
  }
  to {
    opacity: 1;
  }
}
.main-carousel {
  display: flex;
  transition: transform 0.5s ease-in-out; /* Animación de cambio de imagen */
}

.main-carousel-item {
  flex: 0 0 100%; /* Cada item ocupa el 100% del ancho */
  position: relative;
}

.main-carousel-item img {
  width: 100%;
  height: 240px;
  object-fit: cover; /* Asegura que la imagen cubra todo el espacio sin distorsionarse */
}


@media (max-width: 768px) {
  .carousel-caption {
    font-size: 14px;
  }
}